<template>
  <div class="learnMsg__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>学习资料
    </div>
    <a-row :gutter="30">
      <a-col :span="14">
        <div class="msg">
          <div class="msg_search">
            <a-row type="flex" justify="end">
              <a-col :span="6">
                <a-input-search
                  placeholder="请输入"
                  enter-button="搜索"
                  v-model="leftTitle"
                  allowClear
                  @search="Search"
                />
              </a-col>
            </a-row>
          </div>
          <div class="msg_content" style="height: 700px">
            <v-card-list
              ref="listLeftCard"
              :getDataApi="getLeftDataApi"
              :renderItem="renderLeftItem"
              :initPagination="{
                showSizeChanger: false,
                'showQuickJumper': true
              }"
              :colConfig="{
                xs: 8,
                md: 6,
              }"
            >
              <a-empty class="empty" slot="empty" />
            </v-card-list>
          </div>
        </div>
      </a-col>
      <a-col :span="10">
        <div class="msg">
          <div class="msg_title">
            <div class="msg_titleline"></div>收藏的资料
          </div>
          <div class="msg_search">
            <a-row type="flex" justify="end">
              <a-col :span="10">
                <a-input-search
                  v-model="rightTitle"
                  placeholder="请输入"
                  allowClear
                  enter-button="搜索"
                  @search="collectSearch"
                />
              </a-col>
            </a-row>
          </div>
          <div class="msg_collect" style="height: 650px">
            <v-card-list
              ref="listRightCard"
              :getDataApi="getRightDataApi"
              :renderItem="renderRightItem"
              :initPagination="{
                showSizeChanger: false,
                'showQuickJumper': true,
                pageSize: 5
              }"
              :colConfig="{
                span: 24
              }"
            >
              <a-empty class="empty" slot="empty" />
            </v-card-list>
          </div>
          <a-modal
            v-model="visible"
            title="资料详情"
            @ok="handleOk"
            @cancel="cancel"
            :spinning="spinning"
            okText="下载"
            width="1000px"
          >
            <div class="materialShop_shop_text" v-html="content"></div>
          </a-modal>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  appLearnfindUserCollect,
  findPurchaseHistory,
  employmentLearnUserCollect,
  employmentLearnUserDetail
} from '@/api/msg'
import moment from 'moment'

export default {
  name: 'Index',
  data () {
    return {
      visible: false,
      spinning: false,
      fileName: '',
      content: '',
      leftTitle: '',
      rightTitle: '',
      collectList: [],
      collectTotal: 1,
      list: [],
      total: 1,
      materialId: ''
    }
  },
  mounted () {
    // this.initCollect()
  },
  methods: {
    Search () {
      this.$refs.listLeftCard.pageInit()
    },
    collectSearch () {
      this.$refs.listRightCard.pageInit()
    },
    handleOk () {
      window.open(this.fileName)
      this.visible = false
      this.fileName = ''
      this.content = ''
    },
    cancel () {
      this.fileName = ''
      this.content = ''
    },
    getDetail (item) {
      // this.visible = true
      // this.spinning = true
      // const id = item.id
      // employmentLearnUserDetail({ id }).then(res => {
      //   this.spinning = false
      //   this.content = res.data.content
      //   this.fileName = item.fileName
      // })
      window.open(item.fileName)
      // this.$router.push(`/msgShop/msgShopDetail?id=${item.id}`)
    },
    downFile ({ fileName, title, fileType }) {
      this.$spin.show()
      try {
        var blobType
        switch (fileType) {
          case 'Doc':
            blobType = 'application/msword'
            break
          case 'Xls':
            blobType = 'application/vnd.ms-excel'
            break
          case 'Pdf':
            blobType = 'application/pdf'
            break
          case 'Docx':
            blobType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            break
          case 'Xlsx':
            blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            break
          default:
            window.open(fileName)
        }
        if (blobType) {
          this.axios({
          method: 'get',
          url: fileName,
          responseType: 'blob'
        })
          .then(res => {
            const blob = new Blob([res], {
              type: blobType
            })
            const a = document.createElement('a')
            const URL = window.URL || window.webkitURL
            const href = URL.createObjectURL(blob)
            a.href = href
            const type = `.${fileType.toLowerCase()}`
            a.download = title.replace(type, '') + type
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(href)
          })
          .finally(() => {
            this.$spin.hide()
          })
        } else {
        this.$spin.hide()
        }
      } catch (e) {
        this.$spin.hide()
      }
    },
    getCollect (item) {
      this.materialId = item.id
      const params = {
        materialId: item.id
      }
      employmentLearnUserCollect(params)
        .then(res => {
          this.$message.success('取消收藏成功')
          this.$refs.listRightCard.pageInit()
        })
        .catch(err => {
          console.log(err)
        })
    },
    getLeftDataApi (params) {
      return findPurchaseHistory({
        enterprise: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId,
        title: this.leftTitle,
        ...params
      })
    },
    renderLeftItem ({ item, index, data }) {
      return (
        <div class="msg_item">
          <div class="msg_details-item">
            <div class="msg_itemImage">
              <a-avatar shape="square" src={item.thumbImg} size={150} />
            </div>
            <div class="msg_itemTitle" title={item.title}>
              {item.title}
            </div>
            <div class="msg_itemType ">文档类型： {item.fileType}</div>
            <div class="msg_itemType " style="font-size:1px">
              购买时间： <br /> {moment(item.createTime).format('YYYY/MM/DD HH:mm:ss')}
            </div>
            <div class="msg_item_btn">
              {item.fileType === 'Pdf' && (
                <div class="btn-default" onClick={this.getDetail.bind(null, item)}>
                  资料预览
                </div>
              )}
              <div class="btn-default" onClick={this.downFile.bind(null, item)}>
                资料下载
              </div>
            </div>
          </div>
        </div>
      )
    },
    getRightDataApi (params) {
      return appLearnfindUserCollect({
        title: this.rightTitle,
        ...params
      })
    },
    handelClick (item) {
      const a = document.createElement('a')
      a.href = `/material/material-details?id=${item.id}`
      a.target = '_blank'
      a.click()
      a.remove()
      // this.$router.push(`/msgShop/msgShopDetail?id=${item.id}`)
    },
    renderRightItem ({ item, index, data }) {
      return (
        <div
          class="file_item"
          {...{
            on: {
              click: e => {
                this.handelClick(item)
              }
            }
          }}
        >
          <div class="left">
            <img src={item.thumbImg} alt />
          </div>
          <div class="center">
            <div class="file__name" title={item.title}>
              {item.title}
            </div>
            <div class="">文档类型： {item.fileType}</div>
            <div class="">
              文档价格：
              <span style="color: #FF2B2B">￥{item.price}</span>
            </div>
          </div>
          <div class="right">
            <div
              class="icon"
              {...{
                on: {
                  click: e => {
                    e.stopPropagation()
                    this.getCollect(item)
                  }
                }
              }}
            >
              <img src="/img/collect.png" />
            </div>
            <div class="">取消收藏</div>
          </div>
        </div>
      )
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('./index');
.msg_details-item {
  overflow: hidden;
  position: relative;
  .msg_item_btn {
    position: absolute;
    background: #1890ff;
    bottom: -60px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0 0;
    justify-content: space-around;
    transition: all 0.5s;
    .btn-default {
      color: #fff;
      padding: 5px;
      border-radius: 5px;
    }
  }
  &:hover {
    .msg_item_btn {
      bottom: 0px;
    }
  }
}
</style>
